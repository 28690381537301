import React from "react"
import { Container, Row, Col } from 'react-bootstrap'

export default class Footer extends React.Component {

    render() {
        if (this.props.total > 0) {
            return (<div style={{
                position: 'fixed',
                padding: 0,
                margin: 0,
                width: '100%',
                height: 60,
                bottom: 0,
                backgroundColor: '#F00',
                cursor: 'pointer'
            }} onClick={this.props.onClick}>
                <Container>
                    <Row className="text-white text-center font-weight-bold mt-3">
                        <Col><span className="material-icons">shopping_cart</span></Col>
                        <Col>Pedidos</Col>
                        <Col>R$ {this.props.total.toFixed(2).replace(".", ",")}</Col>
                    </Row>
                </Container>
            </div>)
        } else {
            return (<div></div>)
        }
    }
}
