import React from "react"
import { Modal, InputGroup, Button, Form, Row, Col } from 'react-bootstrap'

const InitialForm = {
    qtd: 1,
    obs: '',
}

export default class ModalProduto extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ...InitialForm,
            adds: [],
            multChoice: false,
            name: '',
            description: '',
            price: 0,
            total: 0,
            error: '',
        }
    }

    save = () => {
        if (this.state.adds.length > 0 && this.state.selected.length === 0 && !this.state.multChoice) {
            this.setState({ error: 'Escolha uma das opções' })
        } else if (this.state.total <= 0) {
            this.setState({ error: 'Escolha uma das opções' })
        } else {
            this.props.addOrder({
                product: this.props.product,
                name: this.props.product.name,
                price: this.props.product.sale_price,
                adds: this.state.selected.map(add => {
                    add.name = "-" + add.name
                    return add
                }),
                qtd: this.state.qtd,
                obs: this.state.obs,
                total: this.state.total,
                code: Math.round(Math.random() * 100000)
            })
            this.handleClose()
        }
    }

    updateForm = async (event) => {
        let state = this.state
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value.replace(',', '.')
        state[event.target.name] = value
        await this.setState({ state })
        this.updateTotal()
    }

    updateSelected = async (event, add) => {
        if (event.target.type === 'checkbox') {
            if (event.target.checked) {
                var arrPut = this.state.selected
                arrPut.push(add)
                await this.setState({ selected: arrPut })
            } else {
                var arrDelete = this.state.selected
                arrDelete = arrDelete.filter(i => i.id_additional !== add.id_additional)
                await this.setState({ selected: arrDelete })
            }
        } else {
            await this.setState({ selected: [add] })
        }
        this.updateTotal()
    }

    updateTotal = () => {
        var totalAdds = 0
        this.state.selected.forEach(add => {
            totalAdds += add.sale_price
        })
        if (this.state.qtd > 0) {
            this.setState({ total: this.state.qtd * (this.state.price + totalAdds) })
        } else {
            this.setState({ total: 0 })
        }
    }

    handleClose = () => {
        this.props.setShow(false)
    }

    onShow = () => {
        var adds = []
        var multChoice = false
        if (this.props.product !== null) {
            console.log(this.props.product)
            adds = this.props.product.additionals
        }
        if (this.props.menu.menu_multi !== null) {
            multChoice = this.props.menu.menu_multi
        }
        var form = { ...InitialForm }
        var selected
        if (this.props.halfOrder !== null) {
            form.qtd = this.props.halfOrder.qtd
            form.obs = this.props.halfOrder.obs
            selected = this.props.halfOrder.adds
        } else {
            selected = []
        }
        this.setState({
            ...form,
            price: this.props.product.sale_price,
            total: this.props.product.sale_price,
            adds,
            selected,
            multChoice,
            error: '',
        })
        //console.log(this.props.product)
    }

    sub = async () => {
        if (this.state.qtd === 1) {
            await this.setState({ qtd: 0.5 })
        } else if (this.state.qtd === 0.5) {
            await this.setState({ qtd: 0.5 })
        } else {
            await this.setState({ qtd: this.state.qtd - 1 })
        }
        this.updateTotal()
    }

    add = async () => {
        if (this.state.qtd === 0.5) {
            await this.setState({ qtd: 1 })
        } else {
            await this.setState({ qtd: this.state.qtd + 1 })
        }
        this.updateTotal()
    }

    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.handleClose} onShow={this.onShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes do Pedido</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Image img={this.props.product.image} />
                        <Col>
                            <Row>
                                <Col>
                                    <h4 className="text-left">{this.props.product.name}</h4>
                                </Col>
                                <Col>
                                    <h4 className="text-muted text-right">{this.state.price.toFixed(2).replace(".", ",")}</h4>
                                </Col>
                            </Row>
                            <p className="text-secondary text-left">{this.props.product.description}</p>
                        </Col>
                    </Row>
                    <Form className="mt-3">
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Label>Quantidade</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <Button variant="outline-secondary" onClick={this.sub}>-</Button>
                                        </InputGroup.Prepend>
                                        <InputGroup.Text>{this.state.qtd === 0.5 ? '1/2' : this.state.qtd}</InputGroup.Text>
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary" onClick={this.add}>+</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                            {this.state.adds.length > 0 && <Row className="mt-3">
                                <Col>
                                    <fieldset>
                                        <Form.Label>{this.state.multChoice ? 'Selecione' : 'Selecione uma Opção'}</Form.Label>
                                        <Form.Group>
                                            {this.state.adds.map(add =>
                                                <div className={"custom-control custom-" + (this.state.multChoice ? "checkbox" : "radio")} key={add.id_additional}>
                                                    <input className="custom-control-input"
                                                        onChange={(event) => this.updateSelected(event, add)}
                                                        type={this.state.multChoice ? "checkbox" : "radio"}
                                                        id={add.id_additional}
                                                        name="adds" />
                                                    <label className="custom-control-label" htmlFor={add.id_additional}>
                                                        {add.sale_price === 0 ? add.name : `R$ ${add.sale_price.toFixed(2).replace(".", ",")} - ${add.name}`}
                                                    </label>
                                                </div>
                                            )}
                                        </Form.Group>
                                    </fieldset>
                                </Col>
                            </Row>}
                            <Row>
                                <Col>
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="obs"
                                        value={this.state.obs}
                                        onChange={this.updateForm} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    <h4 className="text-left">Total: R$ {this.state.total.toFixed(2)}</h4>
                    <div className="text-danger text-center">
                        <span>{this.state.error}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={this.handleClose}>Voltar</Button>
                    <Button variant="primary" onClick={this.save}>Adicionar</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const Image = (props) => {
    if (props.img !== '') {
        return (<div style={{
            border: '1px solid #ccc',
            height: 100,
            width: 100,
            marginLeft: '10px',
            borderRadius: 20,
            overflow: 'hidden'
        }}>
            <img style={{ height: '100%' }} src={props.img} alt="Imagem produto" />
        </div>)
    } else {
        return (<div></div>)
    }
}