import React from "react"
import { Modal, InputGroup, Button, Form, Row, Col } from 'react-bootstrap'

const InitialForm = {
    selected: [],
    qtd: 1,
    obs: '',
}

export default class ModalPromocao extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ...InitialForm,
            adds: [],
            name: '',
            description: '',
            price: 0,
            total: 0,
            error: '',
        }
    }

    save = () => {
        var faltaMarcar = false
        this.state.adds.forEach(add => {
            if (!add.multChoice && add.selected.length === 0) {
                faltaMarcar = true
            }
        })
        if (this.state.total <= 0) {
            this.setState({ error: 'Digite a quantidade' })
        } else if (faltaMarcar) {
            this.setState({ error: 'Escolha todas as opções' })
        } else {
            var adds = []
            this.state.adds.forEach(add => {
                if (add.name === '') {
                    adds.push({
                        name: add.itens[0].product.name,
                        id_additional: 0,
                        sale_price: 0,
                    })
                } else {
                    adds.push({
                        name: add.name,
                        id_additional: 0,
                        sale_price: 0,
                    })
                    add.selected.forEach(item => {
                        adds.push({
                            name: "-"+item.product.name,
                            id_additional: 0,
                            sale_price: item.product.sale_price,
                        })
                    })
                }
            })
            this.props.addOrder({
                product: this.props.product,
                name: this.props.product.name,
                price: this.props.product.sale_price,
                adds: adds,
                qtd: this.state.qtd,
                obs: this.state.obs,
                total: this.state.total,
                code: Math.round(Math.random() * 100000)
            })
            this.handleClose()
        }
    }

    updateForm = async (event) => {
        let state = this.state
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value.replace(',', '.')
        state[event.target.name] = value
        await this.setState({ state })
        this.updateTotal()
    }

    updateSelected = async (event, add, item) => {
        if(event.target.type === 'checkbox'){
            if(event.target.checked){
                add.selected.push(item)
            } else {
                add.selected = add.selected.filter(i => i !== item)
            }
        } else {
            add.selected = [item]
        }
        this.updateTotal()
    }

    updateTotal = () => {
        var totalAdds = 0
        this.state.adds.forEach(add => {
            if (add.name === '') {
                totalAdds += add.itens[0].product.sale_price
            } else {
                add.selected.forEach(item => {
                    totalAdds += item.product.sale_price
                })
            }
        })
        if (this.state.qtd > 0) {
            this.setState({ total: this.state.qtd * (this.state.price+totalAdds) })
        } else {
            this.setState({ total: 0 })
        }
    }

    handleClose = () => {
        this.props.setShow(false)
    }

    onShow = () => {
        var adds = []
        if (this.props.product !== null) {
            console.log(this.props.product)
            adds = this.props.product.additionals.map(add => {
                var multChoice = false
                add.itens.forEach(item => {
                    if(item.qtd === '0'){
                        multChoice = true
                    }
                })
                return { ...add, selected: [], multChoice } 
            })
        }
        var form = { ...InitialForm }
        this.setState({
            ...form,
            price: this.props.product.sale_price,
            total: this.props.product.sale_price * form.qtd,
            adds,
            error: '',
        })
        //console.log(this.props.product)
    }

    sub = async () => {
        if (this.state.qtd === 1) {
            await this.setState({ qtd: 1 })
        } else {
            await this.setState({ qtd: this.state.qtd - 1 })
        }
        this.updateTotal()
    }

    add = async () => {
        if (this.state.qtd === 0.5) {
            await this.setState({ qtd: 1 })
        } else {
            await this.setState({ qtd: this.state.qtd + 1 })
        }
        this.updateTotal()
    }

    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.handleClose} onShow={this.onShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes do Pedido</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Image img={this.props.product.image} />
                        <Col>
                            <Row>
                                <Col>
                                    <h4 className="text-left">{this.props.product.name}</h4>
                                </Col>
                                <Col>
                                    <h4 className="text-muted text-right">{this.state.price.toFixed(2).replace(".", ",")}</h4>
                                </Col>
                            </Row>
                            <p className="text-secondary text-left">{this.props.product.description}</p>
                        </Col>
                    </Row>
                    <Form className="mt-3">
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Label>Quantidade</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <Button variant="outline-secondary" onClick={this.sub}>-</Button>
                                        </InputGroup.Prepend>
                                        <InputGroup.Text>{this.state.qtd === 0.5 ? '1/2' : this.state.qtd}</InputGroup.Text>
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary" onClick={this.add}>+</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                            {this.state.adds.length > 0 && <Row className="mt-3">
                                <Col>
                                    {this.state.adds.map(add => {
                                        if (add.name === '') {
                                            return <Form.Label key={add.itens[0].product.name}>{add.itens[0].product.name}</Form.Label>
                                        } else {
                                            return (<fieldset key={add.name}>
                                                <Form.Label>{add.name}</Form.Label>
                                                <Form.Group>
                                                    {add.itens.map(item =>
                                                        <div className={"custom-control custom-" + (item.qtd === '0' ? "checkbox" : "radio")} key={add.name + item.id_product}>
                                                            <input className="custom-control-input"
                                                                onChange={(event) => this.updateSelected(event, add, item)}
                                                                type={item.qtd === '0' ? "checkbox" : "radio"}
                                                                id={add.name + item.id_product}
                                                                name={add.name} />
                                                            <label className="custom-control-label" htmlFor={add.name + item.id_product}>
                                                                {item.product.sale_price === 0 ? item.product.name : `R$ ${item.product.sale_price.toFixed(2).replace(".", ",")} - ${item.product.name}`}
                                                            </label>
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </fieldset>)
                                        }
                                    })}
                                </Col>
                            </Row>}
                            <Row>
                                <Col>
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="obs"
                                        value={this.state.obs}
                                        onChange={this.updateForm} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    <h4 className="text-left">Total: R$ {this.state.total.toFixed(2)}</h4>
                    <div className="text-danger text-center">
                        <span>{this.state.error}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={this.handleClose}>Voltar</Button>
                    <Button variant="primary" onClick={this.save}>Adicionar</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const Image = (props) => {
    if (props.img !== '') {
        return (<div style={{
            border: '1px solid #ccc',
            height: 100,
            width: 100,
            marginLeft: '10px',
            borderRadius: 20,
            overflow: 'hidden'
        }}>
            <img style={{ height: '100%' }} src={props.img} alt="Imagem produto" />
        </div>)
    } else {
        return (<div></div>)
    }
}