import React from "react"
import { Navbar, Nav, FormControl, InputGroup, Button } from 'react-bootstrap'

class Header extends React.Component {

    render() {
        return (<>
            <Navbar bg="primary" expand="md" variant="dark">
                <Navbar.Brand><img style={{ height: 40 }} src="./images/logo.svg" alt="logo carolar" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link onClick={() => console.log('Brasília - DF')}>Brasília - DF</Nav.Link>
                    </Nav>
                    <InputGroup style={{
                        height: 38,
                        maxWidth: 300,
                        borderRadius: 19,
                        overflow: 'hidden',
                        borderWidth: 0
                    }}>
                        <FormControl
                        style={{backgroundColor: '#FFF', color: '#aaa', borderWidth: 0}}
                            placeholder="Pesquisar"
                            aria-label="Pesquisar"
                            aria-describedby="basic-addon2"
                        />
                        <InputGroup.Append>
                            <Button style={{backgroundColor: '#FFF', color: '#aaa', borderWidth: 0}}><span className="material-icons">search</span></Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Navbar.Collapse>
            </Navbar>
        </>)
    }
}

export default Header
