import React from "react"
import { Container, Card, Row, Col } from 'react-bootstrap'
import { post } from '../Helpers/api'
import Header from "../Components/Header"
import Loading from "../Components/Loading"

export default class Categorias extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            stores: [],
            categories: [],
            states: [],
            cities: [],
            loading: false,
        }
    }

    componentDidMount() {
        this.update()
    }

    update = () => {
        this.setState({ loading: true })
        post('Menu/home', {
        }, (json) => {
            if (json.error) {
                this.setState({ error: json.ret, loading: false })
            } else {
                console.log(json.ret)
                this.setState({ ...json.ret, loading: false })
            }
        })
    }

    loja = (id) => {
        this.props.history.push('/loja/' + id)
    }

    render() {
        return (
            <Container fluid style={{ padding: 0 }}>
                <Header />
                <Container style={{ paddingTop: 40 }}>
                    <Loading show={this.state.loading} />
                    <Row>
                        {this.state.stores.map((loja, i) => 
                        <Col key={loja.id_tudok}>
                            <Card style={{ maxWidth: '200px', textAlign: 'center', cursor: 'pointer' }}
                                onClick={() => this.loja(loja.id_tudok)}>
                                <Card.Img src="https://carolar.com/files/dashboard/1586869750-PIZZA-HUT_SANTO-DESCONTO.jpg" alt={loja.nome} />
                                <Card.Body className="">
                                        <Card.Title>{loja.nome}</Card.Title>
                                        <Card.Text>{loja.menu_type}<br/>Entrega {loja.menu_delivery_delay}' - R${`${parseFloat(loja.menu_fee).toFixed(2)}`.replace(".", ",")}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>)}
                    </Row>
                </Container>
            </Container>
        )
    }
}
