import React from "react"
import { Container, Tabs, Tab, Row, Col } from 'react-bootstrap'
import Lightbox from 'react-image-lightbox'
import { post } from '../Helpers/api'
import Loading from "../Components/Loading"
import ModalProduto from "../Components/ModalProduto"
import ModalPedidos from "../Components/ModalPedidos"
import ModalPromocao from "../Components/ModalPromocao"
import Footer from "../Components/Footer"

export default class Lojas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            menu: {
                whatsapp: "",
                colorPrimary: '',
                colorSecondary: '',
                colorText: '',
                delivery_fee: 0,
                showIcons: false,
                enable: false,
                logo: '',
                delivery_delay: 0,
                type: '',
                info: '',
            },
            half_pizza_price_big: false,
            promotions: [],
            categories: [],
            products: [],
            orders: [],
            total: 0,
            halfOrder: null,
            selectedProducts: [],
            selected: 0,
            image: '',
            openImageModal: false,
            openProductModal: false,
            productModal: {},
            openPromotionModal: false,
            promotionModal: {},
            openSaleModal: false,
            loading: false,
            error: true
        }
    }

    addOrder = (order) => {
        var orders = this.state.orders
        orders.push(order)
        this.calcTotal(orders)
    }

    removeOrder = (order) => {
        var orders = this.state.orders
        orders = orders.filter(o => order.code !== o.code)
        if (orders.length === 0) {
            this.setState({ openSaleModal: false })
        }
        this.calcTotal(orders)
    }

    calcTotal = (orders) => {
        var total = 0
        var halfOrder = null
        orders.forEach(order => {
            if (order.qtd === 0.5 && halfOrder === null) {
                halfOrder = order
            } else if (order.qtd === 0.5 && halfOrder !== null) {
                if (this.state.half_pizza_price_big) {
                    if (order.price > halfOrder.price) {
                        order.total = order.price
                        halfOrder.total = 0
                    } else {
                        order.total = halfOrder.price
                        halfOrder.total = 0
                    }
                } else {
                    order.total = (order.price + halfOrder.price) / 2
                    halfOrder.total = 0
                }
                halfOrder.code = order.code
                halfOrder.adds = []
                halfOrder.obs = ''
                halfOrder = null
            } else {
                halfOrder = null
            }
        })
        orders.forEach(order => {
            total += order.total
        })
        if (this.state.menu.menu_show_fee === true && total !== 0) {
            total += parseFloat(this.state.menu.menu_fee)
        }
        this.setState({ orders, total, halfOrder: halfOrder })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.update()
    }

    getID = (hostName) => {
        console.log(hostName)
        switch (hostName) {
            case 'carolar.com':
                return {
                    id: '1021409',
                    logo: 'https://icon-icons.com/descargaimagen.php?id=141808&root=2303/ICO/128/&file=breakfast_hotel_icon_141808.ico',
                    name: 'Pizzaria Maroto',
                    logo512: 'https://icon-icons.com/descargaimagen.php?id=141808&root=2303/ICO/512/&file=breakfast_hotel_icon_141808.ico',
                    logo192: 'https://www.phpclasses.org/browse/view/image/file/118477/name/android-icon-192x192.png',
                }
            case 'localhost':
                return {
                    id: '6865857',
                    logo: 'https://icon-icons.com/descargaimagen.php?id=141808&root=2303/ICO/128/&file=breakfast_hotel_icon_141808.ico',
                    name: 'Remanso do Para',
                    logo512: 'https://icon-icons.com/descargaimagen.php?id=141808&root=2303/ICO/512/&file=breakfast_hotel_icon_141808.ico',
                    logo192: 'https://www.phpclasses.org/browse/view/image/file/118477/name/android-icon-192x192.png',
                }
            default:
                return null
        }
    }

    setSite = (client) => {
        var link = document.createElement('link'),
            oldLink = document.getElementById('favicon');
        console.log(document.getElementById('favicon').href)
        link.id = 'favicon';
        link.rel = 'icon';
        link.href = client.logo;
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
        var myDynamicManifest = {
            "short_name": client.name,
            "name": client.name,
            "icons": [
                {
                    "src": client.logo,
                    "sizes": "64x64 32x32 24x24 16x16",
                    "type": "image/x-icon"
                },
                {
                    "src": client.logo192,
                    "type": "image/png",
                    "sizes": "192x192"
                },
                {
                    "src": client.logo512,
                    "type": "image/ico",
                    "sizes": "512x512"
                }
            ],
            "start_url": window.location.href,
            "display": "standalone",
            "theme_color": "#000000",
            "background_color": "#ffffff"
        }
        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
        document.title = client.name;
    }

    setName = (name) => {
        /*var link = document.createElement('link')
        var oldLink = document.getElementById('favicon')
        link.id = 'favicon';
        link.rel = 'icon';
        link.href = client.logo;
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);*/
        var myDynamicManifest = {
            "short_name": name,
            "name": name,
            "icons": [
                {
                    "src": "favicon.ico",
                    "sizes": "64x64 32x32 24x24 16x16",
                    "type": "image/x-icon"
                },
                {
                    "src": "logo192.png",
                    "type": "image/png",
                    "sizes": "192x192"
                },
                {
                    "src": "logo512.png",
                    "type": "image/png",
                    "sizes": "512x512"
                }
            ],
            "start_url": ".",
            "display": "standalone",
            "theme_color": "#000000",
            "background_color": "#ffffff"
        }
        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
        document.title = name;
    }

    update = () => {
        this.setState({ loading: true })
        var id = this.props.match.params.id
        if (id === undefined) {
            id = localStorage.getItem('menu')
            if (id === null) {
                this.setState({ error: true })
            } else {
                id = JSON.parse(id)
            }
        }
        /*if (id === undefined) {
            var client = localStorage.getItem('id')
            if (client === null) {
                client = this.getID(window.location.hostname)
                localStorage.setItem('id', JSON.stringify(client))
            } else {
                client = JSON.parse(client)
            }
            id = client.id
            this.setSite(client)
        }*/
        if (id !== undefined && id !== null) {
            if (!/^[0-9]+$/.test(id)) {
                this.setName(id.replace('-', ' '))
            }
            localStorage.setItem('menu', JSON.stringify(id))
            this.setState({ error: false })
            post('Menu/loja', {
                id
            }, (json) => {
                if (json.error) {
                    this.setState({ error: true, loading: false })
                } else {
                    json.ret.cfg.config = JSON.parse(json.ret.cfg.config)
                    var config = json.ret.cfg.config
                    var menu = json.ret.menu
                    //console.log(json.ret)
                    //console.log(config)
                    //console.log(menu)
                    config.product = config.product.map(product => {
                        product.additionals = config.addictional.filter(add => {
                            var has = false
                            product.additionals.forEach(id => {
                                if (id === add.id_additional) {
                                    has = true
                                }
                            })
                            return has
                        })
                        return product
                    })
                    config.product = config.product.filter(product => !(product.visible_online === false))
                    config.product.sort((p1, p2) => p1.cod - p2.cod)
                    config.product_category = config.product_category.filter(cat => !(cat.visible_online === false))
                    var dateNow = new Date()
                    let dayOfWeek = dateNow.getDay() + 1
                    let timeInMinutes = dateNow.getHours() * 60 + dateNow.getMinutes()
                    json.ret.promotions.forEach(p => {
                        p.enable = false
                    })
                    //console.log(dayOfWeek+" - "+timeInMinutes+" - "+dateNow.getHours()+":"+dateNow.getMinutes())
                    json.ret.promotionDates.forEach(date => {
                        var timeInit = parseInt(date.HR_START) * 60 + parseInt(date.MIN_START)
                        var timeEnd = parseInt(date.HR_END) * 60 + parseInt(date.MIN_END)
                        //console.log('enable '+date.DAY+" - "+timeInit+" <> "+timeEnd+" - "+date.HR_START+":"+date.MIN_START+" <> "+date.HR_END+":"+date.MIN_END)
                        if (parseInt(date.DAY) === dayOfWeek &&
                            timeInMinutes >= timeInit &&
                            timeInMinutes <= timeEnd) {
                            json.ret.promotions.forEach(p => {
                                if (p.ID_PROMOTION === date.ID_PROMOTION) {
                                    p.enable = true
                                }
                            })
                        }
                    })
                    json.ret.promotionItens = json.ret.promotionItens.sort((a, b) => {
                        return parseInt(a.ID_PROMO_ITEM) - parseInt(b.ID_PROMO_ITEM)
                    })
                    json.ret.promotions = json.ret.promotions.filter(p => p.enable && p.STATUS === '1')
                    json.ret.promotions.forEach(p => {
                        var filteredItens = json.ret.promotionItens
                            .filter(item => item.ID_PROMOTION === p.ID_PROMOTION)
                            .map(item => ({
                                id_product: item.ID_PRODUCT,
                                qtd: item.AMOUNT,
                                group: item.GROUP_ORDER,
                                product: config.product.filter(p => p.id_product === parseInt(item.ID_PRODUCT))[0]
                            }))
                        var groups = []
                        filteredItens.forEach(item => {
                            if (item.group === '') {
                                groups.push({
                                    name: '',
                                    itens: [item]
                                })
                            } else {
                                var group = null
                                groups.forEach(g => {
                                    if (g.name === item.group) {
                                        group = g
                                    }
                                })
                                if (group !== null) {
                                    group.itens.push(item)
                                } else {
                                    groups.push({
                                        name: item.group,
                                        itens: [item]
                                    })
                                }
                            }
                        })
                        config.product.push({
                            id_product: p.ID_PROMOTION,
                            id_category: 0,
                            typo: 0,
                            cod: p.COD,
                            name: p.NAME,
                            description: p.description,
                            image: p.image,
                            sale_price: parseFloat(p.VALUE),
                            additionals: groups,
                        })
                    })
                    var state = {
                        half_pizza_price_big: config.configs.half_pizza_price_big,
                        categories: config.product_category,
                        products: config.product,
                        menu: menu,
                        selected: config.product_category[0].id_category,
                        loading: false
                    }
                    if (json.ret.promotions.length > 0) {
                        state.categories.unshift({
                            id_category: 0,
                            name: config.configs.combo_name,
                            icon: "",
                            description: "",
                            priority: 0,
                            status: 0,
                        })
                        state.selected = 0
                    }
                    //console.log(state)
                    this.setState(state)
                }
            })
        }
    }

    showImage = (url) => {
        this.setState({ openImageModal: true, image: url })
    }

    showProduct = (product) => {
        if (product.id_category === 0) {
            this.setState({ openPromotionModal: true, promotionModal: product })
        } else if (product.amount_in_stock !== 0) {
            this.setState({ openProductModal: true, productModal: product })
        }
    }

    render() {
        if (this.state.error) {
            return (<>
                <Container className="blockquote text-center">
                    <h1 className="mb-4 mt-5">Menu não encontrado</h1>
                </Container >
            </>)
        } else {
            return (<>
                <Container className="blockquote text-center" style={{
                    backgroundImage: "url()"
                }}>
                    <img src="" id="bg" alt=""></img>
                    <Loading show={this.state.loading} />
                    <h1 className="mb-4 mt-5">{this.state.menu.nome}</h1>
                    <Tabs variant="tabs"
                        onSelect={selected => this.setState({ selected })} activeKey={this.state.selected} style={{ justifyContent: 'center' }}>
                        {this.state.categories.map(cat => <Tab eventKey={cat.id_category}
                            key={cat.id_category}
                            title={cat.name}>
                            <div>
                                {this.state.products
                                    .filter(product => product.typo === 0)
                                    .filter(product => product.id_category === cat.id_category)
                                    .map(product =>
                                        <Row className="justify-content-center edit_hover_class" key={product.id_product}>
                                            <Col md="8" key={product.id_product}
                                                style={{
                                                    borderBottom: '1px dotted #ccc',
                                                    cursor: product.amount_in_stock === 0 ? 'not-allowed' : 'pointer',
                                                }}>
                                                <div style={{ height: '30px' }} onClick={() => this.showProduct(product)}></div>
                                                <Row>
                                                    <Image img={product.image} showImage={this.showImage} />
                                                    <Col onClick={() => this.showProduct(product)}>
                                                        <Row>
                                                            <Col>
                                                                <h4 className={product.amount_in_stock === 0 ? "text-left text-danger" : "text-left"}>
                                                                    {product.name}
                                                                    {product.amount_in_stock === 0 ? ' - Esgotado' : ''}
                                                                </h4>
                                                            </Col>
                                                            <Col>
                                                                <h4 className="text-muted text-right">{product.sale_price.toFixed(2).replace(".", ",")}<span className="material-icons">add</span></h4>
                                                            </Col>
                                                        </Row>
                                                        <p className="text-secondary text-left">{product.description}</p>
                                                    </Col>
                                                </Row>
                                                <div style={{ height: '30px' }} onClick={() => this.showProduct(product)}></div>
                                            </Col>
                                        </Row>)}
                            </div>
                        </Tab>)}
                    </Tabs>
                    <h2 className="mb-4 mt-5">Informações</h2>
                    <p>Whatsapp: <a href={"https://wa.me/55" + this.state.menu.menu_whatsapp + "?text=Adorei%20seu%20artigo"} rel="noopener noreferrer" target="_blank">
                        <Tel tel={this.state.menu.menu_whatsapp} />
                    </a></p>
                    <p>Endereço: {this.state.menu.menu_address}</p>
                    {this.state.menu.menu_fee > 0 && <p>Taxa de entrega: R$ {parseFloat(this.state.menu.menu_fee).toFixed(2).replace(".", ",")}</p>}
                    <p>{this.state.menu.menu_address_delivery}</p>
                    <p dangerouslySetInnerHTML={{ __html: this.state.menu.menu_info }}></p>
                    <h2 className="mb-4 mt-5">Horário de funcionamento</h2>
                    <p dangerouslySetInnerHTML={{ __html: this.state.menu.menu_opened }}></p>
                    <div>
                        <br />
                        <br />
                        <br />
                        <p>Feito com <i className="fa fa-heart" aria-hidden="true"></i> pelo <a href="https://sistematudok.com/">Sistema TudOK</a>.</p>
                        <br />
                        <br />
                    </div>
                    {this.state.total <= 0 && <a className="whatsapp" rel="noopener noreferrer" href={"https://wa.me/55" + this.state.menu.menu_whatsapp + "?text=Olá, gostaria de fazer um pedido"} target="_blank">
                        <i style={{ marginTop: '16px' }} className="fa fa-whatsapp"></i>
                    </a>}
                    <ModalPedidos
                        orders={this.state.orders}
                        menu={this.state.menu}
                        removeOrder={this.removeOrder}
                        total={this.state.total}
                        whatsapp={this.state.menu.menu_whatsapp}
                        show={this.state.openSaleModal}
                        setShow={show => this.setState({ openSaleModal: show })} />
                    <ModalProduto
                        product={this.state.productModal}
                        halfOrder={this.state.halfOrder}
                        menu={this.state.menu}
                        addOrder={this.addOrder}
                        showImage={this.showImage}
                        show={this.state.openProductModal}
                        setShow={show => this.setState({ openProductModal: show })} />
                    <ModalPromocao
                        product={this.state.promotionModal}
                        addOrder={this.addOrder}
                        showImage={this.showImage}
                        show={this.state.openPromotionModal}
                        setShow={show => this.setState({ openPromotionModal: show })} />
                    {this.state.openImageModal && <Lightbox mainSrc={this.state.image} onCloseRequest={() => this.setState({ openImageModal: false })} />}
                </Container >
                <Footer
                    total={this.state.total}
                    onClick={() => this.setState({ openSaleModal: true })} />
            </>)
        }
    }
}

const Tel = (props) => {
    var tel = `${props.tel}`
    if (tel.length === 11) {
        return `(${tel.substring(0, 2)}) ${tel.substring(2, 3)} ${tel.substring(3, 7)}-${tel.substring(7, 11)}`
    } else {
        return `(${tel.substring(0, 2)}) ${tel.substring(2, 6)}-${tel.substring(6, 10)}`
    }
}

const Image = (props) => {
    if (props.img !== '' && props.img !== null) {
        let urlImage = props.img.replace('https://carolar.com/', 'https://sistematudok.com/others/')
        return (<div style={{
            border: '1px solid #ccc',
            height: 100,
            width: 100,
            marginLeft: '10px',
            borderRadius: 20,
            overflow: 'hidden'
        }} onClick={() => props.showImage(urlImage)}>
            <img style={{ height: '100%' }} src={urlImage} alt="Imagem produto" />
        </div>)
    } else {
        return (<div></div>)
    }
}