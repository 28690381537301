import React from "react"
import { Modal, Button, Row, Col } from 'react-bootstrap'

export default class ModalPedidos extends React.Component {

    save = () => {
        var text = `Olá, gostaria de realizar um pedido:\r\n\r\n`
        this.props.orders.forEach(order => {
            text += `${order.qtd === 0.5 ? '1/2' : order.qtd}x ${order.product.name}\r\n`
            order.adds.forEach(add => {
                text += `   _${add.name}_\r\n`
            })
            if (order.obs !== '') {
                text += `   _${order.obs}_\r\n`
            }
        })
        if(this.props.menu.menu_show_fee === true){
            text += `Taxa de Entrega\r\n`
        }
        text += `\r\n`
        text += `O total dos produtos deu *R$ ${this.props.total.toFixed(2)}*`
        text = window.encodeURIComponent(text)
        window.open(`https://api.whatsapp.com/send?phone=55${this.props.whatsapp}&text=${text}`, "_blank");
        this.handleClose()
    }

    handleClose = () => {
        this.props.setShow(false)
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Finalizar Pedido</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.orders.map((order, i) => <Row key={'order-' + i} className="del"
                        onClick={() => this.props.removeOrder(order)}>
                        <Col><span>{order.qtd === 0.5 ? '1/2' : order.qtd}x {order.product.name}
                            {order.adds.map(add => <SubItem key={'order-' + i + '-' + add.name} text={add.name} />)}
                            {order.obs !== '' && <SubItem text={order.obs} />}
                        </span></Col>
                        <span className="mr-2">R$ {order.total.toFixed(2)}</span>
                        <span id="del-btn" className="material-icons mr-2">delete_outline</span>
                    </Row>)}
                    {this.props.menu.menu_show_fee === true && <Row>
                        <Col><span>Taxa de entrega</span></Col>
                        <span className="mr-5">R$ {parseFloat(this.props.menu.menu_fee).toFixed(2)}</span>
                    </Row>}
                    <p className="text-muted mt-3">O valor total pode alterar por conta da taxa de entrega</p>
                    <Row className="mt-1 mr-2">
                        <Col className="text-left font-weight-bold">
                            <span>Total Produtos:</span>
                        </Col>
                        <Col className="text-right font-weight-bold">
                            <span>R$ {this.props.total.toFixed(2)}</span>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={this.handleClose}>Voltar</Button>
                    <Button variant="primary" onClick={this.save}><i className="fa fa-whatsapp"></i> Enviar Pedido</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const SubItem = (props) => <span className="text-muted"><br />&nbsp;&nbsp;&nbsp;&nbsp;{props.text}</span>