import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import Categorias from "./Pages/Categorias"
import Loja from "./Pages/Loja"

export default class App extends React.Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/loja/:id' component={Loja} />
                    <Route exact path='/admin' component={Categorias} />
                    <Route exact path='/:id' component={Loja} />
                    <Route path='*' component={Loja} />
                </Switch>
            </BrowserRouter>
        );
    }
}